<script setup lang="ts">
const props = defineProps<{ content?: string }>()
const { translation } = useTranslation(props.content, getLanguageCode())
const body = computed(() => translation.success && translation.visible ? translation.text : props.content)
</script>

<template>
  <div class="content-rich">
    <Component :is="contentToVNode(body)" v-if="body" />
    <slot name="footer" />
  </div>
</template>
