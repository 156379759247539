<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  status?: mastodon.v1.Status | null
  closeFunc?: () => void
}>()
const { status } = toRefs(props)

const commentsBox = ref<HTMLElement>()
const commentsOpen = ref(false)
const account = computed(() => status.value?.account)

const route = useRoute()
const isSelf = useSelfAccount(account)
const draft = useNuxtApp().$mediaUpload?.getDraft({ status })
const isEditable = ref(draft?.value?.isChanged ?? false)

watch(() => route.path, () => {
  close()
})

async function deleteStatus() {
  const id = status.value?.id
  if (id) {
    await $fetch<mastodon.v1.Status>(`/api/statuses/${id}`, {
      method: 'DELETE',
    })
    removeCachedStatus(id)
    close()
  }
}

function openComments() {
  commentsOpen.value = true
  if (commentsBox.value) {
    window.setTimeout(() => {
      commentsBox.value?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 300)
  }
}

function startEdit() {
  isEditable.value = true
  commentsOpen.value = false
}

function close() {
  if (typeof props.closeFunc === 'function') {
    props.closeFunc()
    return
  }
  useRouter().push({ path: useRoute().path, query: { s: undefined } })
}
</script>

<template>
  <UiPopoverModal open @request-close="close">
    <div>
      <Portal v-if="isSelf" to="modal-header">
        <div v-if="status" class="flex-row-center">
          <button type="button" class="compact" @click="startEdit">
            <span class="icon-pencil" />
          </button>
          <button type="button" class="compact" @click="deleteStatus()">
            <span class="icon-trash" />
          </button>
        </div>
      </Portal>
      <template v-if="status">
        <div class="content-box-layout">
          <div class="content-box">
            <PublishWidget v-if="isEditable" :status="status" @close="close" />
            <ContentRich v-else :content="status.content">
              <template #footer>
                <footer>
                  <StatusTags :status="status" />
                </footer>
                <div class="sticky-footer">
                  <UiBar corner padding>
                    <StatusSocialBar :status="status" @require-reply="openComments" />
                  </UiBar>
                  <StatusTranslation :content="status.content" />
                </div>
                <section class="related full-width-content">
                  <h4>{{ $t('status-related_posts') }}</h4>
                  <StatusSimilarList :id="status.id" />
                </section>
              </template>
            </ContentRich>
          </div>
          <aside v-if="commentsOpen" ref="commentsBox" :class="{ open: commentsOpen }">
            <StatusCommentsPanel :status="status" @request-close="commentsOpen = false" />
          </aside>
        </div>
      </template>
      <CommonNotFound v-else />
    </div>
  </UiPopoverModal>
</template>

<style lang="scss">
.content-box-layout {
  --aside-w: min(38cqi, calc(75rem - var(--content-width)));
  display: grid;
  grid-template-columns: 1fr 0px;
  transition: grid-template-columns 0.25s;

  aside {
    grid-column: 1;
    background-color: var(--color-panel-minor);
    overflow: auto;
    overscroll-behavior: contain;
    display: grid;
    grid-template-rows: 0px;
    transition: grid-template-rows 0.5s;
    grid-template-columns: auto min(100% - var(--padding-base) * 2, var(--max-width-text-block)) auto;

    &>* {
      grid-column: 2;
    }
  }

  aside.open {
    grid-template-rows: 1fr;
  }

  &:has(aside.open) {
    @media (width > 920px) {
      grid-template-columns: minmax(var(--aside-w), var(--content-width)) var(--aside-w);

      aside.open {
        grid-column: 2;
        // grid-template-columns: var(--padding-base) 1fr var(--padding-base);
        position: sticky;
        top: 0;
        margin-top: calc(var(--base-size) * -1);
        max-height: var(--popover-max-height);
      }
    }
  }
}

.content-box {
  width: var(--content-width);
  max-width: 100%;

  .sticky-footer {
    --ui-filter-blur: 30px;
    --ui-opacity: 90%;
    margin-left: calc(var(--padding-base) * -1);
    position: sticky;
    bottom: var(--sticky-footer-bottom, var(--padding-small));
    width: fit-content;
    margin: 0;
  }
  .content-rich:has(.status-similar-list) {
   padding-bottom: 0;
  }
  .related {
    margin-top: var(--base-size);
    background-color: var(--color-panel-minor);
    padding-block: var(--padding-base);
    h4 {
      padding-inline: var(--padding-base);
      margin: 0;
      font-size: var(--font-size-1);
    }
  }
}
</style>
