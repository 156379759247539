<script setup lang="ts">
import type { mastodon } from '#types'
import type { StatusWithReplies } from '~/types'

interface CollapseItem extends StatusWithReplies {
  isOpen?: boolean
}

const props = defineProps<{
  items: StatusWithReplies[]
}>()
const emit = defineEmits<{
  (evt: 'remove' | 'update', payload: mastodon.v1.Status): void
}>()

const data = computed<CollapseItem[]>(() => props.items.map(item => ({ ...item, isOpen: true })).sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()))

const toggleItem = (item: CollapseItem) => {
  if (item.replies.length) {
    item.isOpen = !item.isOpen
  }
}
</script>

<template>
  <div>
    <div v-for="item in data" :key="item.id" class="tree-item">
      <div class="tree-item-content" @click="toggleItem(item)">
        <StatusComment :status="item" class="reply-message" @update="emit('update', $event)" @remove="emit('remove', $event)" />
      </div>
      <div v-if="item.replies && item.isOpen" class="tree-item-children">
        <StatusCommentsTree :items="item.replies" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.tree-item {
  margin-left: 20px;
}

.tree-item-content {
  cursor: pointer;
  padding: 5px 0;
}

.toggle-icon {
  display: inline-block;
  width: 20px;
}

.tree-item-children {
  margin-left: 20px;
}
</style>
