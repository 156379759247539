<script setup lang="ts">
import type { mastodon } from '#backend/types'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const emit = defineEmits<{
  (evt: 'require-reply'): void
  (evt: 'remove' | 'update', payload: mastodon.v1.Status): void
}>()

const isSelf = useSelfAccount(() => props.status.account)
const isUpdate = ref(false)
const isDelete = ref(false)

async function toggleFavorite() {
  isUpdate.value = true
  const action = props.status.favourited ? 'unfavourite' : 'favourite'
  const status = await $fetch<mastodon.v1.Status>(`/api/statuses/${props.status.id}/${action}`, { method: 'POST' })
  emit('update', status)
  isUpdate.value = false
}

async function deleteStatus() {
  isDelete.value = true
  await $fetch<mastodon.v1.Status>(`/api/statuses/${props.status.id}`, { method: 'DELETE' })
  emit('remove', props.status)
  isDelete.value = false
}
</script>

<template>
  <div class="status-comment-actions">
    <button class="compact" :class="{ active: status.favourited }" :disabled="isUpdate" @click="toggleFavorite">
      <SpinnerIcon v-if="isUpdate" />
      <template v-else>
        <span class="icon-heart" />
        <span class="counter">{{ status.favouritesCount }}</span>
      </template>
    </button>
    <button class="compact" :class="{ active: status.repliesCount }" @click="emit('require-reply')">
      <span class="icon-reply" />
      <span class="counter">{{ status.repliesCount }}</span>
    </button>
    <button v-if="isSelf" type="button" class="compact" :disabled="isDelete" @click="deleteStatus()">
      <SpinnerIcon v-if="isDelete" />
      <span v-else class="icon-trash" />
    </button>
  </div>
</template>

<style lang='scss'>
.status-comment-actions {
  --icon-size: 1.2rem;
  position: relative;
  display: flex;
  gap: var(--padding-mini);

  >*:not(.active) {
    color: color-mix(in lab, var(--color-text) 70%, transparent);
  }

  .counter {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: var(--font-size-micro);
    opacity: 0.6;
  }
}
</style>
