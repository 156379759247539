<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const emit = defineEmits<{
  (evt: 'remove' | 'update', payload: mastodon.v1.Status): void
}>()

const contentNode = computed(() => props.status.content ? contentToVNode(props.status.content) : undefined)
</script>

<template>
  <div class="status-comment">
    <div class="status-comment-header">
      <AccountBadge :account="status.account">
        <template #meta>
          <div :title="timeTo(status.createdAt)">{{ localizedTime(status.createdAt) }}</div>
        </template>
      </AccountBadge>
    </div>
    <Component :is="contentNode" v-if="contentNode" />
    <footer>
      <StatusCommentActions :status="status" @update="emit('update', $event)" @remove="emit('remove', $event)" />
    </footer>
  </div>
</template>

<style>
.status-comment {
  --content-block-margin: var(--padding-mini);
  --font-size-caption: var(--font-size-micro);
  display: grid;
  grid-template-columns: var(--button-height) 1fr;
  background-color: transparent;
  font-size: var(--font-size-sm);
  margin-block-end: var(--padding-base);

  &>* {
    grid-column: 2;
  }

  .status-comment-header {
    grid-column: 1 / 3;
    margin-block-end: var(--padding-small);

    &>* {
      display: block;
      width: fit-content;
    }

    .popup-trigger {
      color: color-mix(in lab, var(--color-text) 60%, transparent);
    }
  }

  footer {
    display: grid;
    place-items: flex-end;
  }
}
</style>
