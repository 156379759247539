<script setup lang="ts">
import SpinnerIcon from '../SpinnerIcon.vue'

const props = defineProps<{
  content: string
}>()

const {
  toggle: _toggleTranslation,
  translation,
  enabled: isTranslationEnabled
} = useTranslation(props.content, getLanguageCode())
const showButton = computed(() =>
  isTranslationEnabled &&
  props.content.trim().length
)

const translating = ref(false)
async function toggleTranslation() {
  translating.value = true
  try {
    await _toggleTranslation()
  } finally {
    translating.value = false
  }
}
</script>

<template>
  <div v-if="showButton">
    <button
      :disabled="translating"
      @click="toggleTranslation">
      <SpinnerIcon v-if="translating" />
      <div v-else />
      {{ translation.visible ? $t('menu-show_untranslated') : $t('menu-translate_post') }}
    </button>
  </div>
</template>
