<script setup lang="ts">
import type { mastodon } from '#types'

const props = defineProps<{
  id: string
}>()
const { data: statuses, pending } = useFetch<mastodon.v1.Status[]>(`api/v1/statuses/${props.id}/similar-statuses`)
</script>

<template>
  <div class="status-similar-list">
    <div class="grid uniform">
      <SpinnerIcon v-if="pending" />
      <template v-else>
        <StatusCard v-for="status in statuses" :key="status.id" :status="status" />
      </template>
    </div>
  </div>
</template>

<style lang='scss'>
.status-similar-list {
  .grid {
    position: relative;
    padding: var(--padding-base);
  }
}
</style>
