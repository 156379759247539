<script setup lang="ts">
import type { mastodon } from '#types'
import type { StatusWithReplies } from '~/types'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const emit = defineEmits(['request-close'])

const messages = ref<HTMLElement>()
const { data, refresh } = await useFetch<mastodon.v1.Context>(`/api/statuses/${props.status.id}/context`, { key: `context:${props.status.id}` })

const items = computed(() => {
  const roots: StatusWithReplies[] = []
  const descendants = data.value?.descendants ?? []
  const map = descendants.reduce<Record<string, StatusWithReplies>>((result, item) => ({ ...result, [item.id]: { ...item, replies: [] } }), {})
  for (const item of descendants) {
    if (item.inReplyToId) {
      const parent = map[item.inReplyToId]
      if (parent) {
        parent.replies.push(map[item.id])
      }
    }
    else {
      roots.push(map[item.id])
    }
  }
  return roots
})

function published(status: mastodon.v1.Status) {
  data.value?.descendants.push(status)
  refresh()
}

function removed(status: mastodon.v1.Status) {
  const descendants = data.value?.descendants
  if (Array.isArray(descendants) && descendants.length) {
    const index = descendants.findIndex(({ id, publishId }) => (id && id === status.id) || (publishId && publishId === status.publishId)) ?? -1
    if (index >= 0) {
      descendants.splice(index, 1)
    }
    refresh()
  }
}

function updated(status: mastodon.v1.Status) {
  if (data.value) {
    const descendants = structuredClone(toRaw(data.value.descendants))
    if (Array.isArray(descendants) && descendants.length) {
      const index = descendants.findIndex(({ id, publishId }) => (id && id === status.id) || (publishId && publishId === status.publishId)) ?? -1
      if (index >= 0) {
        descendants[index] = toRaw(status)
      }
      data.value.descendants = descendants
      refresh()
    }
  }
}

async function scrollToLastOne() {
  if (messages.value) {
    await nextTick()
    messages.value.scrollTop = messages.value.scrollHeight
  }
}

watch(data, scrollToLastOne)

onMounted(scrollToLastOne)
onReactivated(refresh)
</script>

<template>
  <div ref="messages" class="status-comments-panel">
    <header>
      <button class="compact" @click="emit('request-close')">
        <span class="icon-chevron-left" />
      </button>
    </header>
    <div class="messages">
      <StatusCommentsTree :items="items" class="reply-message" @update="updated" @remove="removed" />
    </div>
    <PublishReplyEditor :status="status" @send="published" />
  </div>
</template>

<style>
.status-comments-panel {
  display: grid;
  grid-template-rows: var(--base-size) 1fr auto;

  header {
    display: flex;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 1000;
  }

  .mini-editor {
    position: sticky;
    bottom: 0;
    z-index: 1000;
  }
}
</style>
