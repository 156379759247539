<script setup lang="ts">
import { EditorContent } from '@tiptap/vue-3'
import type { mastodon } from '#types'
import { uploadContent } from '~/lib/publish'
import { htmlToText } from '#backend/utils/content-parse'

const props = defineProps<{
  status: mastodon.v1.Status
}>()
const emit = defineEmits<{
  (evt: 'send', payload: mastodon.v1.Status): void
}>()

const { checkLogin } = useAuth()
const isUploading = ref(false)

const { editor, files } = useTiptap({
  content: computed(() => ''),
  editable: !(isUploading.value ?? false),
  autofocus: true,
})

const html = computed(() => removeEmpty(editor.value?.getHTML() ?? ''))
const isEmpty = computed(() => {
  if (files?.value.length) {
    return false
  }
  if (html.value) {
    return htmlToText(html.value).trim().replace(/^(@\S+\s?)+/, '').replaceAll(/```/g, '').trim().length === 0
  }
  return true
})
const isPublishDisabled = computed(() => isUploading.value || isEmpty.value)

watch([editor, isUploading], ([editor, isUploading]) => {
  if (editor?.isEditable !== !isUploading) {
    editor?.setEditable(!isUploading)
  }
})

function removeEmpty(html: string) {
  return html.replace(/<(p|h1|h2|h3|h4|h5|h6)[^>]*>[\s\n\r]*<\/\1>/g, '').trim()
}

async function publish() {
  debugger
  if (!checkLogin() || isPublishDisabled.value) {
    return
  }
  if (html.value) {
    editor.value?.commands.setContent('')
    const status = await uploadContent({
      html: html.value,
      uploadMedia: getUploadMediaFromHtml(html.value, files?.value ?? []),
      publishId: crypto.randomUUID(),
      inReplyToId: props.status.id,
    })
    if (status) {
      emit('send', status)
    }
  }
}
</script>

<template>
  <div v-if="isHydrated" class="mini-editor">
    <EditorContent :editor="editor" />
    <button :aria-disabled="isPublishDisabled" @click="publish">
      <SpinnerIcon v-if="isUploading" />
      {{ $t('action-reply') }}
    </button>
  </div>
</template>

<style>
.mini-editor {
  display: grid;
  grid-template-columns: 1fr auto;
  background-color: var(--color-panel);
  margin-bottom: var(---padding-base);
  min-height: calc(var(--base-size) * 2);
}
</style>
